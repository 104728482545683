import { Input, InputProps } from "@chakra-ui/react";
import React from "react";

import { $g1Color } from "../@chakra-ui/gatsby-plugin/G1Style";

import { Component } from "../utils/TypesAndConstants";

interface G1InputProps extends InputProps {
    component: Component ;
    disabled?: boolean ;
    registerInput?: any ;
}

export const G1Input = (props: G1InputProps) => {
    const { component, type, registerInput, onChange, ...rest } = props ;
    const variant = (type === "search") ? "search" : "field" ;

    return <Input 
        type={type}
        bg={$g1Color(`${component}.${variant}.bg`)}
        color={$g1Color(`${component}.${variant}.write`)}
        borderColor={$g1Color(`${component}.${variant}.border`)}
        _hover={{ borderColor: $g1Color(`${component}.${variant}.hover.border`) }}
        _focus={{ borderColor: $g1Color(`${component}.${variant}.select.border`) }}
        _placeholder={{ color: $g1Color(`${component}.${variant}.placeholder`) }}
        onChange={onChange}
        {...registerInput}
        {...rest}
    /> ;
}