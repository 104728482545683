import { Text } from "@chakra-ui/layout";
import React from "react";

import { config } from "../config/config";

const VersionDisplay = () => {
  return <Text fontSize="xx-small" textAlign="center">{config.app.version}</Text> ;
};

export default VersionDisplay;
