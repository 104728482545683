import { Box, Heading, Text, Stack, VStack } from "@chakra-ui/layout";
import { FormControl, FormLabel, Image, useToast } from "@chakra-ui/react";
import React, { ChangeEvent, FormEvent, useState } from "react";
import { navigate } from "gatsby";

import { $isemail } from "foundation-ts/commons";
import { $trim } from "foundation-ts/strings";

import { $g1Color, $g1IconURL } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { G1Button } from "../../components/G1Button";
import { G1Input } from "../../components/G1Input";
import VersionDisplay from "../../components/VersionDisplay";
import { config } from "../../config/config";
import { sendReinitializationPasswordMail } from "../../services/authentication.service";
import { defaultInfoToast } from "../../utils/toast";

export const ReinitPwdView = () => {

    const [isSubmitting, setSubmitting] = useState(false) ;
    const [email, setEmail] = useState('') ;

    const toast = useToast() ;

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault() ;
        setSubmitting(true) ;
        sendReinitializationPasswordMail(email) ;
        toast(defaultInfoToast("Si un utilisateur existe avec cet email, alors il recevra un mail de réinitialisation de mot de passe")) ;
        toast(defaultInfoToast("Vous allez être automatiquement redirigé vers la page d'authentification")) ;
        setTimeout(() => {
            setSubmitting(false) ;
            navigate(config.app.loginURL!) ;
        }, 2000) ;
    }

    const handleMailChange = (e: ChangeEvent<HTMLInputElement>) => {
        const mail = $trim(e.currentTarget.value) ;
        setEmail(mail) ;
    };

    return (
        <Box py="5%">
            <Box color={$g1Color('connection.logo.write')} bg={$g1Color('connection.logo.bg')} paddingTop="8" paddingBottom="2" px="8" maxW="md" mx="auto" rounded={{ base: "0", md: "2xl" }}>
                <VStack spacing="4" align="stretch">
                    <Stack direction="row" alignItems="center" spacing="2">
                        <Image src={$g1IconURL('connection.logo.iconurl')} alt="Application logo" boxSize="72px" />
                        <Heading as="h1" textAlign="center" size="lg" fontWeight="bold">
                            {config.app.title}
                        </Heading>
                    </Stack>
                    <Text textAlign="center" fontWeight="bold" fontSize="20px">
                        Renseignez votre email afin de recevoir un lien de réinitialisation de mot de passe
                    </Text>
                    <form onSubmit={handleSubmit}>
                        <Stack spacing="6" bg={$g1Color('connection.form.bg')} color={$g1Color('connection.form.write')} py="8" px={{ base: "4", md: "10" }} shadow="base" rounded="lg" paddingBottom="1em">
                            <FormControl>
                                <FormLabel fontWeight="light">Adresse mail</FormLabel>
                                <G1Input component="connection.form" type="email" name="email" placeholder="test@test.com" autoFocus onChange={handleMailChange} onBlur={handleMailChange} onInput={handleMailChange} />
                            </FormControl>
                            <G1Button component="connection" variant="ok" title="Envoyer" type="submit" size="lg" isLoading={ isSubmitting } disabled={ isSubmitting || !$isemail(email) } />
                        </Stack>
                    </form>
                    <VersionDisplay />
                </VStack>
            </Box>
        </Box>
    ) ;
} ;