import { Box } from "@chakra-ui/react";
import { RouteComponentProps } from "@reach/router";
import React from "react";

import { $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { useColorContext } from "../../hooks/useColorContext";

export interface PublicLayoutProps extends RouteComponentProps {
  children: React.ReactNode;
}

export const PublicLayout = ({ children }: PublicLayoutProps) => {
  useColorContext() ;
  return (
    <Box as="section" bg={$g1Color('connection.bg')} px={{ base: "2", md: "5" }} py="2">
      {/* <Box px={{ base: "2", md: "8" }} py="auto"> */}
      {children}
      {/* </Box> */}
    </Box>
  );
};
