import React from "react";

import { PublicLayout } from "../components/layouts/PublicLayout";
import { ReinitPwdView } from "../containers/login/ReinitPwdView";

const ReinitPwdPage = () => {
    return (
        <PublicLayout>
            <ReinitPwdView />
        </PublicLayout>
    ) ;
}

export default ReinitPwdPage ;
